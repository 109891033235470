#root {
  background-color: #282c34;
}

main {
  background-color: #282c34;
}


footer a {
  font-size: 25px;
  margin-left: 4px;
}

.App {
  text-align: center;
}

.App-header {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Lobby-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.lobby-title {
  padding: 40px 0;
}

.Login-header {
  
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.Signup-header {
  
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row {
  width: 100%;
  /*margin-bottom: 30px;*/
}

.gameRow {
  padding: 30px;
}

.tradeCommandForm {
  font-size: 18px;
  width: 60%;
}

.tradeCommandForm input {
  flex: auto;
}

#market {
  padding-top: 10px;
}

.marketFlash {
  -webkit-animation: flash linear 1s 1;
  animation: flash linear 1s 1;
  color: yellow;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

#CheatSheet {
  margin-bottom: 30px;
}

#rulesCheatSheetText {
  color: black;
  font-size: 14px;
  text-align: left;
  padding: 0.75rem;
}

#rulesCheatSheetText span {
  color: red;
}

#rulesCheatSheetText .nav {
  margin: 5px;
}

#rulesCheatSheetText .card-header,
#rulesCheatSheetText .list-group-item {
  padding: 0.5rem 1rem;
}

#tradeLog {
  margin-bottom: 30px;
  font-size: 16px;
}

#tradeLog .list-group-item {
  background-color: transparent;
}

#tradeLog .list-group-item-primary {
  color: #b8daff; /* make light for dark background */
}

.FullGame {
  margin: 200px;
  text-align: center;
}

#enterRoomCard {
  /*  */
  align-items: center;
  margin: 14%;
  /* margin: 35%; */
  padding: 8% 0%;
  /* padding: 3% 0%; */
  margin-top: 0px;
  /* margin-bottom: 10px; */
  border-radius: 15px;
}

#roomListCard {
  /*  */
  align-items: center;
  margin: 14%;
  /* margin: 35%; */
  padding: 8% 0%;
  /* padding: 3% 0%; */
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 15px;
}

#loginSignupFormCard {
  /*  */
  align-items: center;
  /* margin: 14%; */
  margin: 35%;
  /* padding: 8% 0%; */
  padding: 3% 0%;
  margin-top: 180px;
  margin-bottom: 10px;
  border-radius: 15px;
}

#signupFormCard {
  /*  */
  font-size: calc(2px + 2vmin);
  align-items: center;
  /* margin: 14%; */
  margin: 35%;
  /* padding: 8% 0%; */
  padding: 2% 0%;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 15px;
}

#userInfoFormCard {
  /*  */
  /* align-items: center; */
  /* margin: 14%; */
  margin: 0%;
  /* padding: 8% 0%; */
  padding: 3% 0%;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 15px;
}

#leaderboardCard {
  border-radius: 15px;
}

#userInfoFormCard .card-title {
  color: yellow;
}


.red {
  color: red;
}

#leaderboardTable {
  /* margin: 0%;
  padding: 3% 0%;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 15px; */
  margin: auto;
  display: block;
}

#roomListTable {
  /* margin: 0%;
  padding: 3% 0%;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 15px; */
  margin: auto;
  display: block;
}

.signupParagraph {
  margin-bottom: 0px;
}

.footerRedirect {
  margin-top: 10px;
}


#timer {
  float: left;
}

#logoutText {
  float: right;
  color: red;
}
